import 'slick-carousel';
import MmenuLight from "mmenu-light";
import "magnific-popup";
import Inputmask from "inputmask";

$(document).ready(function(){
  const phonemask = new Inputmask({"mask": "+7 (999) 999-99-99"}).mask(".phone")
  const emailmask = new Inputmask({ alias: "email"}).mask(".email")
});

const breakpoint = {
  // Small screen / phone
  sm: 576,
  // Medium screen / tablet
  md: 768,
  // Large screen / desktop
  lg: 992,
  // Extra large screen / wide desktop
  xl: 1200
};

document.addEventListener(
    "DOMContentLoaded", () => {
        const menu = new MmenuLight(
            document.querySelector( "#my-menu" ), "(max-width: 767px)"
        );

        const navigator = menu.navigation({
            title: 'Stros',
        });

        const drawer = menu.offcanvas({
            // options
        });

        document.querySelector( 'a[href="#my-menu"]' )
            .addEventListener( 'click', ( evnt ) => {
                evnt.preventDefault();
                if(document.querySelector( 'body' ).classList.contains('mm-ocd-opened')) {
                  drawer.close();
                } else {
                  drawer.open();
                }

            });


    }
);

const $status = $('.projects-slider-slider_number');
const $slider = $('.projects-img-slider');
const $slider0 = $('.projects-info-slider');

$slider.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
    const i = (currentSlide || 0) + 1;
    $status.text(`${i  } / ${  slick.slideCount}`);
});

$slider.slick({
  nextArrow: '.projects-slider__next',
  prevArrow: '.projects-slider__prev',
  dots: false,
  slidesToShow: 1,
  variableWidth: true,
  asNavFor: '.projects-info-slider',
});

$slider0.slick({
  arrows: false,
  dots: false,
  fade: true,
  slidesToShow: 1,
  asNavFor: '.projects-img-slider'
});

$slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
   $('.slick-current').removeClass('slide-current');
 });

$slider.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
    $('.slick-current').addClass('slide-current');
 });


const $status2 = $('.reviews-slider-info');
const $slider2 = $('.reviews-slider');

$slider2.on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $('.slick-current').removeClass('slide-current');
 });

$slider2.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
    $('.slick-current').addClass('slide-current');
 });

$slider2.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
    const i = (currentSlide || 0) + 1;
    $status2.text(`${i  } / ${  slick.slideCount}`);
});

$slider2.slick({
  nextArrow: '.reviews-slider__next',
  prevArrow: '.reviews-slider__prev',
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true,
});


const $slider3 = $('.card-img-slider');
$slider3.slick({
  nextArrow: '.card-slider__next',
  prevArrow: '.card-slider__prev',
  slidesToShow: 1,
  dots: true,
  centerMode: true,
  variableWidth: true
});

const $slider4 = $('.card-specifications-slider');
$slider4.slick({
  nextArrow: '.card-specifications-slider__next',
  prevArrow: '.card-specifications-slider__prev',
  slidesToShow: 1,
  centerMode: true,
  centerPadding: '0px',
});

const $slider5 = $('.project-detail-slider-for')
$slider5.slick({
  nextArrow: '.project-detail-slider__next',
  prevArrow: '.project-detail-slider__prev',
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  fade: true,
  asNavFor: '.project-detail-slider-nav'
});


const $slider6 = $('.project-detail-slider-nav')

$slider6.on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $('.slick-current').removeClass('project-detail-slider-highlight');
 });

$slider6.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
    $('.slick-current').addClass('project-detail-slider-highlight');
 });

$slider6.slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  asNavFor: '.project-detail-slider-for',
  arrows: false,
  dots: false,
  fade: false,
  centerMode: true,
  centerPadding: '0',
  focusOnSelect: true,
  infinite: true,
  mobileFirst:true
});


$(".catalog-card-title-link").on('click', function(e) {
   e.preventDefault();
   const {hash} = this;
   $('html, body').animate({
       scrollTop: $(hash).offset().top
     }, 300, function(){
       window.location.hash = hash;
     });
});

$('.certificate-link').magnificPopup(
  {
    type: 'image',
    mainClass: 'mfp-with-zoom',
    zoom: {
      enabled: true,
      duration: 300,
      easing: 'ease-in-out',
      opener(openerElement) {
        return openerElement.is('img') ? openerElement : openerElement.find('img');
      }
    }}
  );


$(document).ready(function() {
  $(window).resize(function () {
    if ($(window).width() < 450) {
      $('.round-card-slick').slick({});
    } else {
      $('.round-card-slick').slick('unslick');
    }
  });
});

$(document).ready(function() {
  $(window).resize(function () {
    if (window.matchMedia("(max-width: 575px)").matches) {
      $(".slider-mobile-clients").not('.slick-initialized').slick({
        nextArrow: '.slider-mobile-clients__next',
        prevArrow: '.slider-mobile-clients__prev',
        slidesToShow: 1,
        slidesToScroll: 1,
      })
      $(".slider-mobile-objects").not('.slick-initialized').slick({
        nextArrow: '.slider-mobile-objects__next',
        prevArrow: '.slider-mobile-objects__prev',
        slidesToShow: 1,
        slidesToScroll: 1,
      })
      $(".slider-mobile-certificates").not('.slick-initialized').slick({
        nextArrow: '.slider-mobile-certificates__next',
        prevArrow: '.slider-mobile-certificates__prev',
        slidesToShow: 1,
        slidesToScroll: 1,
      })
      $('a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
        $('.slider-mobile-clients').slick('setPosition');
        $('.slider-mobile-objects').slick('setPosition');
      })
      $('.certificate-link').magnificPopup(
        {
          type: 'image',
          mainClass: 'mfp-with-zoom',
          zoom: {
            enabled: true,
            duration: 300,
            easing: 'ease-in-out',
            opener(openerElement) {
              return openerElement.is('img') ? openerElement : openerElement.find('img');
            }
          }}
        );
    } else {
      $(".slider-mobile-clients").filter('.slick-initialized').slick('unslick');
      $(".slider-mobile-objects").filter('.slick-initialized').slick('unslick');
      $(".slider-mobile-certificates").filter('.slick-initialized').slick('unslick');
      $('.certificate-link').magnificPopup(
        {
          type: 'image',
          mainClass: 'mfp-with-zoom',
          zoom: {
            enabled: true,
            duration: 300,
            easing: 'ease-in-out',
            opener(openerElement) {
              return openerElement.is('img') ? openerElement : openerElement.find('img');
            }
          }}
        );
    }
  });
});
